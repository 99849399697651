<template>
  <SectionAlt
    id="why-study-at-uni-melb"
    :bg-color="bgColor"
    class="why-study"
    data-test="why-study"
  >
    <h2
      class="title title--md"
      :class="{ 'text-center': whyStudy.cta && whyStudy.cta.link }"
    >
      {{ whyStudy.heading }}
    </h2>
    <div class="grid grid--lg">
      <div
        v-for="(section, index) in whyStudy.sections"
        :key="index"
        class="cell cell--lg cell--tab-1of3 section-alt__inner-flex-items text-left"
      >
        <img
          v-if="section.icon_url"
          role="presentation"
          :alt="section.title"
          :src="section.icon_url"
          class="why-study__icon display-block shim-mb05"
        />
        <h3 class="title title--sm">
          {{ section.title }}
        </h3>
        <p>
          {{ section.content_text }}
        </p>
        <ButtonIcon
          v-if="section.content_link_url"
          :href="section.content_link_url"
          class="btn--text"
          :aria-label="section.content_link_text"
        >
          {{ section.content_link_text }}
        </ButtonIcon>
      </div>
    </div>
    <div
      v-if="whyStudy.cta && whyStudy.cta.link"
      class="why-study__cta shim-mt2"
    >
      <ButtonIcon size="sml" class="btn--secondary" :href="whyStudy.cta.link">
        {{ whyStudy.cta.label }}
      </ButtonIcon>
    </div>
  </SectionAlt>
</template>

<script>
import { ButtonIcon, SectionAlt } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    ButtonIcon,
    SectionAlt,
  },
  props: {
    whyStudy: {
      type: Object,
      required: true,
      validator: (value) => value.sections && value.sections.length,
    },
    bgColor: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="postcss">
.why-study {
  &__icon {
    max-width: 4rem;
  }

  &__cta {
    text-align: left;
    @media (--bp-x-tablet) {
      text-align: center;
    }
  }
}
</style>
